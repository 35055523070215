import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSnackBar.figmaUrl.ios} codeUrl={checklists.componentSnackBar.codeUrl.ios} checklists={checklists.componentSnackBar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Snackbars notify users of processes that have been or will be performed by the app. They appear temporarily, towards the bottom of the screen.
They must not interfere with the user experience, and they do not require user input to disappear.
Snackbars are often used as tooltips/popups to show a message at the bottom of the screen.`}</p>
    <p>{`Use Legion snackbar styles to modify snackbar with lots of attributes that make you easier.`}</p>
    <h3>{`Usage Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`struct ContentView: View {
  var body: some View {
    YourOutmostView {
      //your screen content
    }.snackbar(isShowing: .constant(true))
  }
}

extension View {
  func snackbar(isShowing: Binding<Bool>) -> some View {
    Snackbar(isShowing: isShowing, parent: { self }, title: "Hello")
  }
}
`}</code></pre>
    <h3>{`Usage Theme`}</h3>
    <p>{`Import the theme you prefer: ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`struct ContentView: View {
  var body: some View {
    YourOutmostView {
      //your screen content
    }.snackbar(isShowing: .constant(true))
  }
}

extension View {
  func snackbar(isShowing: Binding<Bool>) -> some View {
    LGNSnackbar(isShowing: isShowing, parent: { self }, title: "Hello")
  }
}
`}</code></pre>
    <h4>{`Apparances of Snackbar :`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Size`}</strong>{` : Medium, or small size`}</li>
      <li parentName="ul"><strong parentName="li">{`style`}</strong>{` : Solid, or soft`}</li>
      <li parentName="ul"><strong parentName="li">{`Variant`}</strong>{`: Default, tertiary, warning, error, information, or success`}</li>
      <li parentName="ul"><strong parentName="li">{`Left content`}</strong>{` : Trailing avatar, Trailing icon, or no left content`}</li>
      <li parentName="ul"><strong parentName="li">{`Title`}</strong>{` : Title only, or title with description (subtitle)`}</li>
      <li parentName="ul"><strong parentName="li">{`Right content`}</strong>{` : Text button or icon button. Text and icon are customizable`}</li>
    </ul>
    <p>{`Customize the colors, in order to adapt with your app color token
Text Color & Background Color`}</p>
    <div className="divi" />
    <h2>{`Sizes`}</h2>
    <p>{`This code sample demonstrates how to modify the style the snackbar using ThemeLGN :`}</p>
    <h3>{`Medium`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-medium-size.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      size: .medium,
      style: .solid,
      variant: .default,
      title: "Title",
      trailingButtonText: "Button"
)
`}</code></pre>
    <h3>{`Small`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-small-size.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      size: .small,
      style: .solid,
      variant: .default,
      title: "Title",
      trailingButtonText: "Button"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Styles`}</h2>
    <p>{`This code sample demonstrates how to modify the style the snackbar using ThemeLGN :`}</p>
    <h3>{`Solid Default`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-solid-default-title-button.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
     isShowing: isShowing,
     parent: { self },
     style: .solid,
     variant: .default,
     title: "Title",
     trailingButtonText: "Button"
)
`}</code></pre>
    <h3>{`Soft Default`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-soft-default-title-icon.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      style: .soft,
      variant: .default,
      title: "Title"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`This code sample demonstrates how to modify the variant of Solid Style the snackbar using ThemeLGN. You can also change the variant to Soft Style if you wish.`}</p>
    <h3>{`Tertiary`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-solid-tertiary-description-button.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      style: .solid,
      variant: .tertiary,
      title: "Title",
      subtitle: "Snackbar message..",
      trailingButtonText: "Button"
)
`}</code></pre>
    <h3>{`Warning`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-solid-warning-icon-title-button.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      style: .solid,
      variant: .warning,
      title: "Title",
      leadingIcon: Image(systemName: "arrow.up.circle"),
      trailingButtonText: "Button"
)
`}</code></pre>
    <h3>{`Information`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-solid-information-avatar-title-button.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      style: .solid,
      variant: .information,
      title: "Title",
      leadingAvatarUrl: "https://yourdomain.com/your-image.png",
      trailingButtonText: "Button"
)
`}</code></pre>
    <h3>{`Error`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-solid-error-icon-description-button.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      style: .solid,
      variant: .error,
      title: "Title",
      subtitle: "Snackbar message..",
      leadingIcon: Image(systemName: "arrow.up.circle"),
      trailingButtonText: "Button"
)
`}</code></pre>
    <h3>{`Success`}</h3>
    <div {...{
      "className": "snackbartype",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-solid-success-avatar-description-button.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
      isShowing: isShowing,
      parent: { self },
      style: .solid,
      variant: .success,
      title: "Title",
      subtitle: "Snackbar message..",
      leadingAvatarUrl: "https://yourdomain.com/your-image.png",
      trailingButtonText: "Button"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isShowing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the binding that decides the appropriate drawing in the body.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the size of the snackbar. `}<inlineCode parentName="td">{`.medium`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`style`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the style of the snackbar. `}<inlineCode parentName="td">{`.solid`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.soft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.solid`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the variant of the snackbar. `}<inlineCode parentName="td">{`.default`}</inlineCode>{`, `}<inlineCode parentName="td">{`.tertiary `}</inlineCode>{`, `}<inlineCode parentName="td">{`.warning`}</inlineCode>{`, `}<inlineCode parentName="td">{`.information`}</inlineCode>{`, `}<inlineCode parentName="td">{`.error`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.success`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.default`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`parent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`parent view to which this snackbar shall be attached`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`place on the screen where the snackbarr should appear. `}<inlineCode parentName="td">{`.top`}</inlineCode>{`, `}<inlineCode parentName="td">{`.center`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.bottom`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bottom`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the main text you want to display on snackbar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleFontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily of your title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color of your title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary900`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subtitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the describtion or subtitle text you want to display on snackbar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subtitleFontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily of your subtitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subtitleColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color of your subtitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary600`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`your snackbar background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.white`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leadingIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the icon you want to display on the left hand side of your snackbar text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leadingIconTint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`your leadingIcon color tint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.black`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leadingAvatarUrl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`when you want to display avatar instead of icon on the left, fill this with image url`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trailingButtonText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text string to display on the action button of your snackbar’s right hand side`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trailingButtonTextTint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`your trailingButtonText tint color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.primary500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trailingButtonIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`when you have no text to display as a trailing button, this icon image will be used instead`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Image(systemName: "xmark")`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trailingButtonIconTint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`your trailingButtonIcon tint color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.black `}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trailingButtonAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`additional action to trigger when user taps on the trailing action button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{ }`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example Project`}</h2>
    <div {...{
      "className": "exampleproject",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Ffdd83fb1-bc81-4221-a459-aedc30380822%2FUntitled.png?table=block&id=512114ea-dfaf-453d-8347-828d5e02ce28&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=1540&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNSnackbar(
  isShowing: isShowing,
  parent: { self },
  style: .solid,
  variant: .default,
  position: .bottom,
  title: "Title",
  titleFontFamily: "Montserrat-Bold",
  titleColor: Color.LGNTheme.tertiary900,
  subtitle: "Snackbar message..",
  subtitleFontFamily: "Montserrat-Ultralight",
  subtitleColor: Color.LGNTheme.tertiary600,
  backgroundColor: .white,
  leadingIcon: Image(systemName: "arrow.up.circle"),
  leadingIconTint: .black,
  leadingAvatarUrl: "https://yourdomain.com/your-image.png",
  trailingButtonText: "Button",
  trailingButtonTextTint: Color.LGNTheme.primary500,
  trailingButtonIcon: Image(systemName: "xmark"),
  trailingButtonIconTint: .black,
  trailingButtonAction: {
      // Your additional action to trigger when user tap on the trailing button icon and dismiss the snackbar
})
`}</code></pre>
    <div className="divi" />
    <h2>{`Important Notes`}</h2>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`Left content can only display either avatar or icon. If you put on both values, only avatar will be displayed`}</li>
        <li parentName="ul">{`Right content can only display one button i.e. text button, or icon button. If you put on both, only text button will appear`}</li>
        <li parentName="ul">{`If you do not specify right content, it will always display right icon button with the default `}<inlineCode parentName="li">{`xmark`}</inlineCode>{` icon.`}</li>
      </ul>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      